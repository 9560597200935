<template>
    <v-container>
        <h3>{{ $t("message.title-shop-news") }}</h3>
        <v-col class="pr-2 pb-2" cols="12">
            <v-form @submit="submitShopNews">
                <v-col cols="12" md="3" xs="6">
                    <span>{{ $t("message.shop") }}</span>
                    <v-autocomplete
                    v-model="shopNews.shopId"
                    @change="filterShop(shopNews.shopId)"
                    class="p-0 mt-1"
                    :items="shopList"
                    item-text="store_name"
                    item-value="id"
                    outlined
                    ></v-autocomplete>
                </v-col>
                <v-row>                
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-news-content") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-textarea
                            v-model="shopNews.newsContent"
                            outlined
                            full-width
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-news-image") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-file-input
                            v-model="shopNews.newsImage"
                            type="file"
                            :prepend-icon="null"
                            persistent-placeholder
                            ref="fileImg"
                            outlined
                            @change="upload"
                            accept="image/*"
                        ></v-file-input>

                        <template>
                            <div class="set-img">
                                <v-img
                                    v-if="this.image_url != null"   
                                    style="border-radius: 15px; border: 1px solid black"
                                    :lazy-src="this.image_url"
                                    :src="this.image_url"
                                    width="220px"
                                    height="120px"
                                    
                                >
                                <v-icon v-if="this.image_url != null" class="icon-close" @click.prevent="deleteImg()">
                                    mdi-close-box
                                </v-icon>
                                </v-img>
                            </div>
                        </template>
                    </v-col>
                </v-row>
                <div class="d-flex flex-row-reverse mt-3 me-0" tile>
                    <div class="m-2">
                        <v-btn type="submit" color="primary" @click.prevent="submitShopNews()">
                        {{ $t("message.submit-button") }}
                        </v-btn>
                    </div>
                    <div>
                        <v-btn color="grey" class="m-2" @click="$router.go(-1)">
                        {{ $t("message.back-button") }}
                        </v-btn>
                    </div>
                </div>
            </v-form>
        </v-col>
    </v-container>
</template>

<script>
export default {
    name: "ShopNews",
    data() {
        return {
            shopNews: {
                shopId: "",                
                newsContent: "",
                newsImage: null
            },
            image_url: null
        }
    },
    methods: {
        submitShopNews() {
            let payload = {
                store_id: this.shopNews.shopId,                
                news_content: this.shopNews.newsContent,
                image_url: this.image_url
            }
            return this.$store.dispatch("shop_module/createShopNews", payload)
        },
        async fetchShopList() {
            await this.$store.dispatch("shop_module/fetchShopAll");
            let shop = this.$store.state.shop_module.shopAllResult
            this.shopNews.shopId = shop[0].id;

            await this.$store.dispatch("shop_module/fetchDetailShopNews", this.shopNews.shopId)
            let detailNews = this.$store.state.shop_module.detailShopNews;
            
            if(detailNews.length == 0) {                
                this.shopNews.newsContent = "";
                // this.shopNews.newsImage = null;
                this.image_url = null;
            } else {                
                this.shopNews.newsContent = detailNews.news_content;
                // this.shopNews.newsImage = detailNews.image_url;
                this.image_url = detailNews.image_url;
            }
        },
        async filterShop(shopId) {
            await this.$store.dispatch("shop_module/fetchDetailShopNews", shopId)
            let detailNews = this.$store.state.shop_module.detailShopNews;

            if(detailNews.length == 0) {                
                this.shopNews.newsContent = "";
                // this.shopNews.newsImage = null;
                this.image_url = null;
            } else {        
                this.shopNews.newsContent = detailNews.news_content;
                // this.shopNews.newsImage = detailNews.image_url;
                this.image_url = detailNews.image_url;
            }
        },
        async upload() {
            if(this.shopNews.newsImage != null) {
                await this.$store.dispatch("shop_module/uploadImageNews", this.shopNews.newsImage)
                let uploadImageNews = this.$store.state.shop_module.shopNewsImage;
                this.image_url = uploadImageNews;
            } else {
                this.image_url = null
            }
        },
        deleteImg() {
            if(this.image_url != null) {
                this.$store.dispatch("shop_module/deleteImageShopNews", 0)
                this.$store.dispatch('uploadImage_module/deleteImgBucket', {
                    path: this.image_url
                });
                this.image_url = null
                this.shopNews.newsImage = null
            }
        }
    },
    mounted() {
        this.$store.dispatch("shop_module/clearStateImageNews")
    },
    created() {
        this.fetchShopList();
    },
    computed: {
        shopList() {
            return this.$store.state.shop_module.shopAllResult
        },
    },
}
</script>

<style>

</style>